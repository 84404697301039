
























































































import axios, { AxiosRequestConfig } from 'axios';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import { Language, Result, SearchResponse, Translations, Video } from '@/types';

@Component
export default class SearchDialog extends Vue {
  jwt: string = '';
  sort: string = 'rel';
  sortKeys: string[] = ['rel', 'newest', 'oldest'];
  isLoading: boolean = false;
  searchQuery: string = '';
  debounce: number | null = null;
  response: SearchResponse | null = null;

  @State tokenUrl!: string;
  @State searchUrl!: string;
  @State mediatorUrl!: string;
  @State languages!: Language[];
  @State translations!: Translations;

  @Getter getSiteLanguage!: Language;
  @Getter findLanguageByLocale!: (locale: string | undefined) => Language | undefined;

  @State searchDialog!: boolean;
  @Mutation setSearchDialog!: (value: boolean) => void;

  @Mutation setVideoDialog!: (value: boolean) => void;
  @Mutation setSelectedVideo!: (value: Video) => void;

  async mounted() {
    this.fetchToken();
  }

  async fetchToken() {
    const response = await axios.get(this.tokenUrl);
    this.jwt = response.data;
  }

  get xsOnly() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get dialog() {
    return this.searchDialog;
  }

  set dialog(value) {
    this.setSearchDialog(value);
  }

  get query() {
    return this.searchQuery;
  }

  set query(value) {
    if (this.debounce) clearTimeout(this.debounce);
    // @ts-ignore
    this.debounce = setTimeout(() => {
      this.searchQuery = value;
    }, 400);
  }

  get siteLanguage() {
    return this.getSiteLanguage?.locale ?? 'en';
  }

  get placeholder() {
    switch (this.siteLanguage) {
      case 'nl':
        return 'Zoek of plak jw.org link...';
      case 'en':
      default:
        return 'Search or paste jw.org link...';
    }
  }

  get searchMessage() {
    return this.response?.pagination?.label ?? this.response?.messages[0].message;
  }

  get sorts() {
    if (!this.response?.sorts) {
      return [];
    }
    return this.sortKeys.map(key => ({
      key,
      label: this.response?.sorts.find(sort => sort.link.includes(key))?.label,
    }));
  }

  get columnCount() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg':
        return 3;
      case 'md':
      case 'sm':
        return 2;
      case 'xs':
      default:
        return 1;
    }
  }

  async onClickResult(result: Result) {
    this.fetchVideo(this.getSiteLanguage.code, result.lank);
  }

  @Watch('searchQuery')
  async onSearchQueryChange(value: string) {
    if (value === null || value === '') {
      this.response = null;
      return;
    }

    const finderRegex = /jw\.org\/finder\?.+&.+/;
    const wtLocaleRegex = /wtlocale=(?<code>[A-Za-z]+)/;
    const localeRegex = /locale=(?<locale>[A-Za-z]+)/;
    const lankRegex = /lank=(?<lank>[\w-]+)/;
    const mediaItemsRegex = /jw\.org\/[\w-]+\/.+#(?<locale>[\w-]+)\/mediaitems\/(?<category>[\w-]+)\/(?<lank>[\w-]+)/;

    if (finderRegex.test(value)) {
      const lang =
        wtLocaleRegex.exec(value)?.groups?.code ??
        this.findLanguageByLocale(localeRegex.exec(value)?.groups?.locale)?.code;
      const lank = lankRegex.exec(value)?.groups?.lank;
      await this.fetchVideo(lang, lank);
      this.searchQuery = '';
      return;
    }
    if (mediaItemsRegex.test(value)) {
      const match = mediaItemsRegex.exec(value);
      const lang = this.findLanguageByLocale(match?.groups?.locale)?.code;
      const lank = match?.groups?.lank;
      await this.fetchVideo(lang, lank);
      this.searchQuery = '';
      return;
    }

    this.fetchResponse(value);
  }

  async fetchVideo(langCode: string | undefined, lank: string | undefined) {
    if (langCode === undefined || lank === undefined) {
      // TODO: Invalid link message(?)
      return;
    }
    const [video] = (
      await axios.get(`${this.mediatorUrl}/media-items/${langCode}/${lank}?clientType=www`)
    ).data.media;
    this.setSelectedVideo(video);
    this.setVideoDialog(true);
  }

  @Watch('sort')
  async onSortChange() {
    this.fetchResponse(this.searchQuery);
  }

  async fetchResponse(query: string) {
    this.isLoading = true;
    const url = `${this.searchUrl}/${this.getSiteLanguage.code}/videos?sort=${this.sort}&q=${query}`;
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${this.jwt}`,
      },
    };

    try {
      const response = await axios.get<SearchResponse>(url, config);
      response.data.results = response.data.results.filter(
        result => result.subtype !== 'videoCategory',
      );
      this.response = response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        return;
      }
      if (error.response?.status === 401) {
        this.fetchToken();
      }
    } finally {
      this.isLoading = false;
    }
  }

  @Watch('getSiteLanguage')
  onSiteLanguageChange() {
    this.searchQuery = '';
    this.response = null;
  }
}
