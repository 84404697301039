































































import axios from 'axios';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';

import { Language, Translations } from './types';

import VideoDialog from '@/components/VideoDialog.vue';
import SearchDialog from '@/components/SearchDialog.vue';
import VideoCategory from '@/components/VideoCategory.vue';
import TranscriptDialog from '@/components/TranscriptDialog.vue';

@Component({
  components: {
    VideoCategory,
    SearchDialog,
    VideoDialog,
    TranscriptDialog,
  },
})
export default class App extends Vue {
  ready: boolean = false;

  @State(state => state.route.params.language) routeLanguage!: string;

  @State mediatorUrl!: string;
  @State languages!: Language[];
  @State translations!: Translations;

  @Getter getSiteLanguage!: Language;
  @Mutation setSiteLanguage!: (value: string) => void;

  @Mutation setLanguages!: (value: Language[]) => void;
  @Mutation setTranslations!: (value: Translations) => void;
  @Mutation setSearchDialog!: (value: boolean) => void;

  async mounted() {
    this.$vuetify.theme.dark = window.matchMedia('(prefers-color-scheme:dark)').matches;
    await this.fetchLanguages();
    this.siteLanguage = this.routeLanguage;
    this.ready = true;
    if (this.routeLanguage === 'nl') this.fetchI18n();
  }

  updateRoute() {
    this.$router.push({ name: 'Home', params: { language: this.siteLanguage } });
  }

  // eslint-disable-next-line class-methods-use-this
  languageLabel(item: Language) {
    return item.name === item.vernacular ? item.name : `${item.name} (${item.vernacular})`;
  }

  languagesUrl(language: string) {
    return `${this.mediatorUrl}/languages/${language}/all?clientType=www`;
  }

  get translationsUrl() {
    return `${this.mediatorUrl}/translations/${this.getSiteLanguage.code}`;
  }

  get siteLanguage() {
    return this.getSiteLanguage?.locale ?? 'en';
  }

  set siteLanguage(language: string) {
    if (language === null) return;
    this.setSiteLanguage(language);
  }

  get guideButtonText() {
    switch (this.siteLanguage) {
      case 'nl':
        return 'Handleiding';
      case 'en':
        return 'Guide';
      default:
        return this.translations.lnkHelpView;
    }
  }

  @Watch('routeLanguage')
  async onRouteLanguageChange(newLang: string) {
    if (!this.languages.some(language => language.locale === newLang)) {
      this.siteLanguage = 'en';
      this.updateRoute();
      return;
    }
    this.siteLanguage = newLang;
  }

  @Watch('siteLanguage')
  async onSiteLanguageChange() {
    (this.$refs.langSelect as any).blur();
    await this.fetchI18n();
    if (this.routeLanguage !== this.siteLanguage) {
      this.updateRoute();
    }
  }

  async fetchI18n() {
    await Promise.allSettled([this.fetchLanguages(), this.fetchTranslations()]);
  }

  async fetchLanguages() {
    type LanguagesRequest = { languages: Language[] };
    const url = this.languagesUrl(this.ready ? this.getSiteLanguage.code : '-');
    const { languages } = (await axios.get<LanguagesRequest>(url)).data;

    // Pinning items to the top of a list has never been harder
    const dutch = languages.filter(language => language.locale === 'nl')[0];
    const english = languages.filter(language => language.locale === 'en')[0];
    const remainder = languages.filter(
      language => language.locale !== 'nl' && language.locale !== 'en',
    );
    remainder.unshift(dutch, english);
    this.setLanguages(remainder);
  }

  async fetchTranslations() {
    type TranslationsRequest = { translations: { [key: string]: Translations } };
    const response = await axios.get<TranslationsRequest>(this.translationsUrl);
    const translations = response.data.translations[this.getSiteLanguage.code];
    this.setTranslations(translations);
  }
}
