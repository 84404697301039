






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { Video } from '@/types';

@Component
export default class VideoSwiper extends Vue {
  @Prop({ required: true })
  videos!: Video[];

  swiperOptions = {
    slidesPerView: 'auto',
    freeMode: true,
    freeModeSticky: true,
    paginationClickable: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      '0': {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: false,
        },
      },
      '600': {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 24,
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: false,
        },
      },
      '1264': {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 24,
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: true,
        },
      },
    },
  };

  @Mutation setVideoDialog!: (value: boolean) => void;
  @Mutation setSelectedVideo!: (value: Video) => void;

  get mdAndUp() {
    return this.$vuetify.breakpoint.mdAndUp;
  }

  onClickVideo(video: Video) {
    this.setSelectedVideo(video);
    this.setVideoDialog(true);
  }
}
