

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Video } from '@/types';

@Component
export default class VideoButton extends Vue {
  @Prop({ required: true })
  videoMedia!: Video | null;

  @State translations!: { [key: string]: string };
}
