



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { Video } from '@/types';

@Component
export default class VideoGrid extends Vue {
  @Prop({ required: true })
  videos!: Video[];

  @Mutation setVideoDialog!: (value: boolean) => void;
  @Mutation setSelectedVideo!: (value: Video) => void;

  onClickVideo(video: Video) {
    this.setSelectedVideo(video);
    this.setVideoDialog(true);
  }
}
