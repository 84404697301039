


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { File, Video } from '@/types';

@Component
export default class CastButton extends Vue {
  @Prop({ required: true })
  videoMedia!: Video | null;
  @Prop({ required: true })
  subtitleMedia!: Video | null;
  @Prop({ required: true })
  subtitleUrl!: string | null;

  @State selectedVideo!: Video;
  @State translations!: { [key: string]: string };

  // eslint-disable-next-line class-methods-use-this
  getChromecastUrl(file: File) {
    const videoUrl = btoa(file.progressiveDownloadURL);
    // sfgc: subtitle foreground color = #ffffff
    // ss: subtitle size = 1.1
    let url = `https://chromecast.smplayer.info/index.php?sfgc=I2ZmZmZmZg==&ss=MS4x&url=${videoUrl}`;
    try {
      url += `&title=${btoa(this.selectedVideo.title.replaceAll('—', '-'))}`;
    } catch (error) {
      // No title then, I guess
    }
    if (this.subtitleUrl !== null) {
      const subtitles = btoa(this.subtitleUrl);
      url += `&subtitles=${subtitles}`;
    }
    return url;
  }
}
