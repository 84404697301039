





































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';

import { Video } from '@/types';

@Component
export default class SubtitleButton extends Vue {
  @Prop({ required: true })
  subtitleMedia!: Video | null;
  @Prop({ required: true })
  subtitleUrl!: string | null;

  @State translations!: { [key: string]: string };

  @Mutation setSubtitleMedia!: (value: Video | null) => void;
  @Mutation setTranscriptDialog!: (value: boolean) => void;

  onClick() {
    this.setSubtitleMedia(this.subtitleMedia);
    this.setTranscriptDialog(true);
  }
}
